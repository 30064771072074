<template>
  <div v-html="html" />
</template>

<script>
export default {
  name: "ViewInBrowser",
  props: {
    accountId: {
      type: String,
      required: true,
    },
    campaignTrackingToken: {
      type: String,
      required: true,
    },
    emailToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      html: null,
    }
  },
  async mounted() {
    try {
      const response = await this.$rest.creative.email
                        .get_email_in_browser(this.accountId, this.campaignTrackingToken, this.emailToken)
      this.html = response?.data?.content;
    } catch (error) {
      this.$store.commit("snackbar/showMessage", {
        content: "An unexpected error occurred. We apologize for the inconvenience.",
        color: "error"
      })
    }
  }
}
</script>